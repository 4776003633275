<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        :max-width="'1500px'"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>

            <v-spacer></v-spacer>
            <v-layout row justify-center align-center v-if="progress">
              <v-progress-circular
                indeterminate
                :size="30"
                :width="7"
                color="purple"
              ></v-progress-circular>
            </v-layout>

            <v-btn
              color="blue darken-1"
              @click="save"
              :disabled="save_disable"
              class="mx-2"
              small
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" @click.stop="close" small>
              Annuler
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="6" md="7">
                  <v-autocomplete
                    v-model="editedItem.article_id"
                    :item-value="'article_id'"
                    :items="articles_list"
                    :filter="customFilter"
                    label="Produit"
                    :readonly="form_list.length > 0"
                    @change="article_change"
                    :rules="[(v) => !!v || 'Produit obligatoire']"
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        (item.product_code ? item.product_code + "-" : "") +
                        item.product_label
                      }}
                    </template>
                    <template slot="item" slot-scope="data">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="
                            data.item.product_code +
                            '-' +
                            data.item.product_label
                          "
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="4" md="3">
                  <v-autocomplete
                    ref="mvmto"
                    v-model="mvm_to"
                    item-text="label"
                    item-value="id"
                    :items="depots"
                    label="Depot de Destination"
                    @change="depot_change"
                    :readonly="form_list.length > 0"
                    :rules="[
                      (v) => !!v || 'Depot obligatoire',
                      (v) =>
                        ligne_exist(editedItem.article_id, v) == false ||
                        'Ligne existe!',
                    ]"
                    dense
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    autocomplete="off"
                    ref="qte22"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.qte"
                    :label="
                      editedItem.unit_name ? editedItem.unit_name : 'Quantité'
                    "
                    readonly
                    :rules="[(v) => !!v || 'Quantité obligatoire']"
                    hide-spin-buttons
                    autofocus
                    v-if="editedItem.article_id"
                    @keydown.enter="save"
                    :disabled="save_disable"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6" v-if="editedItem.article_id">
                  <v-row>
                    <v-col cols="12" sm="1" md="11">
                      <listitems
                        :list="flist"
                        :headers="dispo_headers"
                        :title="'Disponible'"
                        :master="true"
                        :add="false"
                        :del="false"
                        :Update="false"
                        :showstd="true"
                        :Total="true"
                        :print="false"
                        :exp_excel="true"
                        :imp_excel="false"
                        :key="kflist"
                        :multiple="true"
                        @selected_rows="selected_left_change"
                        :laoding="productload"
                        :ipg="5"
                      >
                      </listitems>
                    </v-col>
                    <v-col cols="12" sm="1" md="1" v-if="mvm_to">
                      <v-btn
                        fab
                        color="blue darken-1"
                        @click="add_product"
                        class="my-2"
                        small
                        :disabled="selected_left.length == 0"
                      >
                        >
                      </v-btn>

                      <v-btn
                        fab
                        color="blue darken-1"
                        @click="del_product"
                        class="my-2"
                        small
                        :disabled="selected_right.length == 0"
                      >
                        <span v-html="'<'"></span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="6"
                  v-if="editedItem.article_id && mvm_to"
                >
                  <listitems
                    :list="form_list"
                    :headers="select_headers"
                    :title="'Selection '"
                    :master="true"
                    :add="false"
                    :del="false"
                    :Update="true"
                    :showstd="true"
                    :Total="true"
                    :print="false"
                    :exp_excel="true"
                    :imp_excel="false"
                    :key="frml"
                    @add="add_item"
                    @delete="del_item"
                    :multiple="true"
                    @selected_rows="selected_right_change"
                    :laoding="listload"
                    :ipg="5"
                  >
                  </listitems>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-alert v-model="alert" :type="type_alert" dismissible>{{
              message
            }}</v-alert>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <confirmdialog ref="confirm" />
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
const confirmdialog = () => import("./ConfirmDialog.vue");
const listitems = () => import("./ListItems.vue");
import MVM_DETAIL_UPDATE from "../graphql/Mouvment/MVM_DETAIL_UPDATE.gql";
import PRODUCTION_LIST_MVM from "../graphql/Production/PRODUCTION_LIST_MVM.gql";

export default {
  components: { confirmdialog, listitems },
  name: "mvmdetailprod",
  props: {
    item: Object,
    items: Array,
    showForm: Boolean,
    encours_list: Array,
    default_depots: Array,
    mvm_type: Number,
    boxs: Array,
  },
  data: () => ({
    alert: false,
    save_disable: false,
    type_alert: "info",
    message: "",
    kflist: 100,
    adding: false,
    deleting: false,
    productload: false,
    imp: false,
    title: "Liste Détaillée",
    listload: false,
    isListClose: true,
    list: false,
    datepicker1: false,
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    defaultItem: {},
    editedItem: {},
    combovalue: "",
    isProductClosed: true,
    qte: 0,
    tol: 0,
    mvm_to: null,
    precision: 2,
    depot: {},
    dispo_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Index",
        value: "code",
        selected: true,
      },
      {
        text: "Code",
        value: "ref",
        selected: true,
        hiden: true,
      },
      {
        text: "Quantité",
        value: "qte_rest",
        total: "qte_rest",
        slot: "cur",
        align: "end",
        selected: true,
      },
      {
        text: "Date de péremption",
        value: "expire_date",
        selected: true,
        slot: "date",
        hiden: true,
      },
      {
        text: "Origine",
        value: "origin",
        selected: true,
      },
      {
        text: "Comment",
        value: "comment",
        selected: true,
        edit: true,
        type: "text",
        sm: 12,
        md: 12,
      },
    ],
    select_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Index",
        value: "code",
        selected: true,
      },
      {
        text: "Code",
        value: "ref",
        selected: true,
        hiden: true,
        edit: true,
        type: "text",
        sm: 6,
        md: 6,
        rules: [(v) => !!v || "Code obligatoire"],
      },
      {
        text: "Quantité",
        value: "qte_rest",
        total: "qte_rest",
        slot: "href22",
        align: "end",
        hiden: false,
        selected: true,
        edit: true,
        type: "qte",
        dec: 2,
        sm: 3,
        md: 3,
        rules: [(v) => !!v || "Quantité obligatoire"],
      },
      {
        text: "Date de péremption",
        value: "expire_date",
        selected: true,
        slot: "date",
        hiden: true,
      },
      {
        text: "Origine",
        value: "origin",
        selected: true,
      },
      {
        text: "Box",
        value: "box_id",
        slot: "simple-select",
        selected: true,
        edit: false,
        hiden: true,
        type: "autocomplete",
        item_value: "id",
        item_text: "box",
        subtitle1: "rack",
        subtitle1_label: "Rack",
        subtitle2: "stock_cmpt",
        subtitle2_label: "Stock : ",
        subtitle3: "nbarticle",
        subtitle3_label: "Nb : ",
        select_list: [],
        show_list: [],
        sm: 6,
        md: 6,
        rules: [(v) => !!v || "Box obligatoire"],
      },
      {
        text: "Comment",
        value: "comment",
        selected: true,
        edit: true,
        type: "text",
        sm: 12,
        md: 12,
      },
    ],
    form_list: [],
    prdslist: [],
    selected_left: [],
    selected_right: [],
    frml: 600,
  }),

  computed: {
    depots() {
      return this.$store.state.depots.filter(
        (elm) => elm.ChildCount == 0 && elm.type_id == 2
      );
    },
    flist() {
      let l = this.prdslist.filter(
        (elm) => !this.form_list.map((elm3) => elm3.id).includes(elm.id)
      );
      return l;
    },
    articles_list() {
      let list = [];
      if (this.encours_list) {
        list = this.encours_list.filter(
          (elm) =>
            !this.items
              .map((elm2) => elm2.article_id)
              .includes(elm.article_id) || this.editedItem.id > 0
        );
      }
      return list;
    },
    showDetail() {
      return this.showForm;
    },
    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouvelle Ligne "
          : "Ligne N° ".concat(parseInt(this.item.index) + 1);
      else return "";
    },
    boxs_list() {
      let list = [];
      if (this.boxs)
        list = this.boxs.filter((elm) => elm.depot_id == this.mvm_to);
      return list;
    },
    sumlist_selected() {
      let list = [];
      list = this.form_list ? this.form_list : [];
      return list
        ? parseFloat(
            list
              .reduce((a, b) => a + (b["qte_rest"] || 0), 0)
              .toFixed(this.precision)
          )
        : 0;
    },
  },
  watch: {
    form_list: {
      handler() {
        this.editedItem.qte = this.sumlist_selected;
      },
      deep: true,
    },
  },

  created() {},
  async mounted() {
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        this.mvm_to = this.editedItem.depot_id;
        await this.article_change();
        this.depot_change();
      } else {
        this.editedItem = Object.assign({}, this.defaultItem);
        if (this.depots.length == 1) {
          this.mvm_to = this.depots[0].id;
          this.depot_change();
        }
      }
    }
    this.dispo_headers[1].hiden =
      this.$store.state.options[0].code_produit == "1";
    this.dispo_headers[2].hiden =
      this.$store.state.options[0].code_produit == "0";
    this.select_headers[1].hiden =
      this.$store.state.options[0].code_produit == "1";
    this.select_headers[2].hiden =
      this.$store.state.options[0].code_produit == "0";
  },

  methods: {
    selected_left_change(items) {
      this.selected_left = items;
    },
    selected_right_change(items) {
      this.selected_right = items;
    },
    add_product() {
      if (this.selected_left.length > 0) {
        this.selected_right = [];
        this.selected_left.forEach((element) => {
          element.selected = false;
          this.form_list.unshift(element);
        });
        this.selected_left = [];
        this.editedItem.qte = this.sumlist_selected;
        this.frml++;
        this.kflist++;
      }
    },

    del_product() {
      if (this.selected_right) {
        this.selected_left = [];
        this.selected_right.forEach((element) => {
          this.form_list.splice(
            this.form_list.findIndex((elm) => elm.id == element.id),
            1
          );
        });
        this.selected_right = [];
        this.editedItem.qte = this.sumlist_selected;
        this.frml++;
        this.kflist++;
      }
    },

    async list_mvm() {
      this.form_list = [];

      if (this.editedItem.id >= 0) {
        this.listload = true;
        let v = {
          mvm_detail_id: this.editedItem.id,
        };
        let r = await this.requette(PRODUCTION_LIST_MVM, v);
        if (r) {
          this.form_list = r.production_list_mvm;
        }
      }
      this.listload = false;
      this.frml++;
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    del_item() {
      this.editedItem.qte = this.sumlist_selected;
    },
    add_item(item) {
      item.cmd_id = this.cmd_id;
      item.cmd_name = this.cmd_name;
      this.form_list.splice(item.index, 1, item);
    },

    ligne_exist(p, d) {
      let i = this.items.filter((elm) => elm.id != this.item.id);
      let e = -1;
      e = i.findIndex((elm) => elm.article_id == p && elm.depot_id == d);
      return e >= 0;
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) {
            this.$store.dispatch("Changed", true);
            this.$emit("change");
          }
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async requette(query, v) {
      this.progress = true;

      let r = [];

      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    customFilter(item, queryText) {
      let textlist = [];
      let l = [
        "product_code",
        "product_label",
        "article_code",
        "article_label",
        "nofiche",
        "no_bt",
        "no_order",
        "nocmd",
        "tier_name",
      ];

      l.forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },
    datefr(date) {
      var result = new Date(date);
      return result.toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    sortBy(prop) {
      return function (a, b) {
        return a[prop] > b[prop] ? 1 : -1;
      };
    },
    async depot_change() {
      if (this.editedItem.id < 0) {
        this.form_list = [];
        this.editedItem.qte = 0;
        this.$refs.form.resetValidation();
      }
      let i = this.depots.findIndex((elm) => elm.id == this.mvm_to);

      if (i >= 0) {
        this.depot = this.depots[i];
        this.select_headers[6].text = this.depot.box ? this.depot.box : "Box";
        this.select_headers[6].rules = [
          (v) => !!v || this.select_headers[6].text + " obligatoire",
        ];
        this.select_headers[6].subtitle1_label = this.depot.rack
          ? this.depot.rack
          : "Rack";
        this.select_headers[6].hiden = this.depot.NbRack == 0;
        this.select_headers[6].edit = this.depot.NbRack > 0;
        this.select_headers[6].select_list = this.boxs_list;
        this.select_headers[6].show_list = this.boxs_list;
      }
    },
    async article_change() {
      if (this.encours_list) {
        let i = this.encours_list.findIndex(
          (elm) => elm.article_id == this.editedItem.article_id
        );
        if (i >= 0) {
          this.editedItem.is_expire = this.encours_list[i].is_expire;
          this.editedItem.pu = this.encours_list[i].prixrevient;
        }
        this.dispo_headers[4].hiden = this.editedItem.is_expire == 0;
        this.select_headers[4].hiden = this.editedItem.is_expire == 0;
        if (this.editedItem.id > 0) await this.list_mvm();
        //list possible
        this.prdslist = this.encours_list.filter(
          (elm) => elm.article_id == this.editedItem.article_id
        );
        this.prdslist.sort(this.sortBy("qte_rest"));

        this.kflist++;
      }
    },

    async close() {
      if (this.form_list.length > 0 && this.item.id < 0) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir annuler"
          )
        )
          this.$emit("close", this.items);
      } else this.$emit("close", this.items);
    },
    verifie_double() {
      let ok = true;
      for (let index = 0; index < this.form_list.length; index++) {
        const element = this.form_list[index];
        let list2 = this.form_list.filter(
          (elm) => elm.ref == element.ref && elm.id != element.id
        );

        if (list2.length > 1) {
          this.snackbar_text = "Code " + element.ref + " dupliqué";
          this.snackbar_color = "error";
          this.snackbar = true;
          ok = false;
          break;
        }
      }

      return ok;
    },
    verifie_attribut() {
      let ok = true;
      let l = this.select_headers.filter(
        (elm) => elm.edit == true && elm.hiden != true
      );
      for (let index = 0; index < this.form_list.length; index++) {
        const element = this.form_list[index];

        for (let i = 0; i < l.length; i++) {
          if (!element[l[i].value] && l[i].value != "comment") {
            this.snackbar_text =
              l[i].text + " manquant! Ligne " + (parseInt(index) + 1);
            this.snackbar_color = "error";
            this.snackbar = true;
            ok = false;
            break;
          }
        }
        if (ok && this.editedItem.is_expire == "1" && !element.expire_date) {
          this.snackbar_text =
            "Date péremption manquante! Ligne " + (parseInt(index) + 1);
          this.snackbar_color = "error";
          this.snackbar = true;
          ok = false;
          break;
        }
      }
      return ok;
    },
    async save() {
      if (this.$refs.form.validate()) {
        let ok = true;
        ok = this.verifie_attribut();
        //verfier code liste
        if (ok && this.$store.state.options[0].code_list == "1") {
          let j = this.form_list.findIndex(
            (elm) => elm.ref == null || elm.ref == ""
          );
          if (j >= 0) {
            this.snackbar_text =
              "Code article N° " + parseInt(j + 1) + " manquant!!--verifier";
            this.snackbar_color = "error";
            this.snackbar = true;
            ok = false;
          }
          if (ok) ok = this.verifie_double();
        }

        if (ok) {
          let i;
          let pl = [];
          let boxs = [];
          this.save_disable = true;
          for (let index = 0; index < this.form_list.length; index++) {
            const element = this.form_list[index];
            let elm = {};
            elm.depot_id = this.mvm_to;
            elm.article_id = this.editedItem.article_id;
            elm.ref = element.ref;
            elm.qte = element.qte_rest;
            elm.comment = element.comment;
            elm.pfd_id = element.id;
            elm.box_id = element.box_id;
            elm.expire_date = element.expire_date;
            pl.push(elm);

            //
          }
          if (this.depot.NbRack > 0) {
            for (let index = 0; index < this.form_list.length; index++) {
              const element = this.form_list[index];
              let elm = {};
              elm.article_id = this.editedItem.article_id;
              elm.box_id = element.box_id;
              elm.qte = element.qte;
              boxs.push(elm);
              //
            }
          }
          i = this.depots.findIndex((elm) => elm.id == this.mvm_to);
          if (i >= 0) this.editedItem.depot_to = this.depots[i].depot_name;
          this.editedItem.depot_id = this.mvm_to;

          let v;

          v = {
            mvmdetail: [
              {
                mvm_id: this.item.mvm_id,
                qte: parseFloat(this.editedItem.qte),
                pu: this.editedItem.pu ? this.editedItem.pu : 1,
                article_id: this.editedItem.article_id,
                gamme_id: this.editedItem.gamme_id,
                depot_id: this.editedItem.depot_id,
                fk_unit: this.editedItem.fk_unit,
                write_uid: this.$store.state.me.id,
                comment: this.editedItem.comment,
              },
            ],
            id: this.editedItem.id > 0 ? this.editedItem.id : null,
            factor: 1,
            inserts_list: pl.length > 0 ? pl : null,
            boxs: boxs.length > 0 ? boxs : null,
          };

          let r = await this.maj(MVM_DETAIL_UPDATE, v);
          if (r) {
            if (this.editedItem.id > 0)
              this.items.splice(this.item.index, 1, this.editedItem);
            else {
              this.editedItem.id = r.UpdateMvmDetail;
              this.items.push(this.editedItem);
            }
            this.$emit("change", this.editedItem);
            this.$store.dispatch("Changed", true);
            this.$emit("close", this.items);
          } else {
            this.snackbar_text = " erreur d'enregistrement";
            this.snackbar_color = "error";
            this.snackbar = true;
          }
          this.save_disable = false;
        }
      }
    },
  },
};
</script>
